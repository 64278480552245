import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Awards Distributor",
	component: "AwardsDistributor",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: true,
					hideable: false,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Cards",
					type: "ComponentArray",
					mandatory: true,
					maxItems: null,
					key: "elements",
					whiteList: ["AwardsCard"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/AwardsDistributor/layout001_x2.png`,
						},
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/AwardsDistributor/layout002_x2.png`,
						},
						{
							value: "L003",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/AwardsDistributor/layout003_x2.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "AwardsDistributor",
		title: { tag: "h2", content: "Title" },
		elements: [
			{
				component: "AwardsCard",
				ranking: "Nº 1 ranking El Mundo y Expansión",
				subtitle:
					"Máster Universitario en Práctica Jurídica Especialidad en Derecho Internacional de los Negocios",
				dateTitle: "",
				date: "Convocatorias: Febrero y Octubre 2021",
				studentsTitle: "",
				students:
					"Dirigido a Graduados o Licenciados en Derecho o Título equivalente",
				descriptionTitle: "",
				description:
					"Formación integral para el ejercicio de la profesión de abogado.",
				button: {
					component: "Button",
					buttonText: "Solicita tu admisión",
					appearance: "link",
					size: "small",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
		],
		layout: "L001",
		theme: "defaultAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AwardsDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AwardsDistributor_x2.png`,
	},
});
