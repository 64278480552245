import thumbnailImage from "@axthumbs/thumbnails/inner-title-uddi-2x.png";
import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

const isUDDI = instance.includes("UDDI");

export default createSchema({
	schemaType: "module",
	displayName: "Basic Content",
	component: "BasicContent",
	dataPacks: null,
	category: "content",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Detail",
					type: "RichText",
					key: "detail",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "image",
					hideable: true,
				},
				{
					key: "imageText",
					type: "TextField",
					title: "Image Text",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						...(!isUDDI
							? [
									{
										value: "L001",
										img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/BasicContent/layout001_x2.png`,
									},
							  ]
							: []),
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/BasicContent/layout002_x2.png`,
						},
						{
							value: "L003",
							img: isUDDI
								? thumbnailImage
								: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/BasicContent/layout003_x2.png`,
						},
						{
							value: "L004",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/BasicContent/layout004_x2.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"default",
								"defaultAlt",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "BasicContent",
		title: { content: "Basic content title text", tag: "h2" },
		subtitle: "Subtitle text",
		detail: "Detail text",
		image: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
		},
		imageText: "Image detail",
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: isUDDI ? "L003" : "L001",
		theme: "default",
	},

	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BasicContent_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/BasicContent_x2.png`,
	},
});
