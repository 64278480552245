import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "FAQ",
	component: "FAQ",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h3", content: "Default placeholder" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "FAQ elements",
					type: "ComponentArray",
					whiteList: ["FAQElement"],
					key: "elements",
					maxItems: null,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ['default", "defaultAlt']) },
						{ ...createThemeStyles("iade-theme", ['default", "defaultAlt']) },
						{
							...createThemeStyles("iade-es-theme", ['default", "defaultAlt']),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "FAQ",
		title: { content: "Title", tag: "h3" },
		theme: "default",
		elements: [
			{
				component: "FAQElement",
				title: { content: "Title", tag: "h4" },
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
			},
		],
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Accordion_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Accordion_x2.png`,
	},
});
