import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

const isIADEES = instance.includes("iadeES");
const isIPAM = instance.includes("IPAM");
const isUDDI = instance.includes("UDDI");

export default createSchema({
	schemaType: "module",
	displayName: "Highlighted Carousel",
	component: "HighlightedCarousel",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Slide elements",
					type: "ComponentArray",
					maxItems: null,
					whiteList: ["CarouselSlide"],
					key: "elements",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["accentLight"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HighlightedCarousel",
		elements: [
			{
				component: "CarouselSlide",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				description: "Lorem ipsum dolor sit amet",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: isIADEES ? "link" : "secondary",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			{
				component: "CarouselSlide",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				description: "Lorem ipsum dolor sit amet",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: isIADEES ? "link" : "secondary",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			{
				component: "CarouselSlide",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				description: "Lorem ipsum dolor sit amet",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: isIADEES ? "link" : "secondary",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
		],
		theme: isIPAM || isUDDI ? "accent" : "accentLight",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighlightedCarousel_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighlightedCarousel_x2.png`,
	},
});
