import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Accordion",
	component: "Accordion",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Default placeholder" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Accordion elements",
					type: "ComponentArray",
					whiteList: ["AccordionElement"],
					key: "elements",
					maxItems: null,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "Accordion",
		title: { content: "Title", tag: "h4" },
		theme: "default",
		elements: [
			{
				component: "AccordionElement",
				title: { content: "Title", tag: "h3" },
				componentModules: [
					{
						component: "CardCollection",
						title: { content: "Title", tag: "h2" },
						subtitle: "Lorem Ipsum",
						kind: "BasicCard",
						elements: [
							{
								component: "BasicCard",
								title: "Lorem ipsum",
								description:
									"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
								file: {
									alt: "",
									publicId:
										"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
									url: "",
								},
								veil: 0,
								button: {
									component: "Button",
									buttonText: "Link",
									appearance: "link",
									size: "medium",
									url: {
										href: "",
										linkTo: null,
										newTab: false,
										noFollow: false,
									},
								},
							},
						],
						button: {
							component: "Button",
							buttonText: "Link",
							appearance: "link",
							size: "small",
							url: {
								href: "",
								linkTo: null,
								newTab: false,
								noFollow: false,
							},
						},
						layout: "L001",
					},
				],
			},
		],
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Accordion_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Accordion_x2.png`,
	},
});
