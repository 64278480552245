import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Horizontal Tabs",
	component: "HorizontalTabs",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Tab",
					type: "ComponentArray",
					whiteList: ["HorizontalTabElement"],
					mandatory: true,
					key: "elements",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default"]) },
						{ ...createThemeStyles("iade-theme", ["default"]) },
						{ ...createThemeStyles("iade-es-theme", ["default"]) },
						{ ...createThemeStyles("uddi-theme", ["default"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HorizontalTabs",
		elements: [
			{
				component: "HorizontalTabElement",
				title: "Lorem ipsum",
				componentModules: [
					{
						component: "Table",
						tabHeaderFirst: "Lorem Ipsum",
						tabHeaderSecond: "Lorem Ipsum",
						elements: [
							{
								component: "TableRow",
								title: "Lorem ipsum",
								credits: "0",
								fileName: "pdf",
								file: "",
							},
							{
								component: "TableRow",
								title: "Lorem ipsum",
								credits: "0",
								fileName: "pdf",
								file: "",
							},
							{
								component: "TableRow",
								title: "Lorem ipsum",
								credits: "0",
								fileName: "pdf",
								file: "",
							},
						],
					},
				],
			},
			{
				component: "HorizontalTabElement",
				title: "Lorem ipsum",
				componentModules: [
					{
						component: "BasicContent",
						title: { content: "Basic content title text", tag: "h2" },
						subtitle: "Subtitle text",
						detail: "Detail text",
						image: {
							component: "Image",
							alt: "Alt",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
						},
						imageText: "Image detail",
						button: {
							component: "Button",
							buttonText: "Link",
							appearance: "link",
							size: "medium",
							url: {
								href: "",
								linkTo: null,
								newTab: false,
								noFollow: false,
							},
						},
						layout: "L001",
					},
				],
			},
		],
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HorizontalTabs_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HorizontalTabs_x2.png`,
	},
});
