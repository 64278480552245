import { instance } from "@instance-config";
import { basicModuleList } from "@schemas/modules";
import { createSchema } from "@utils/index";

const filteredModuleList = basicModuleList.filter(
	(element) => element !== "StickyFooterCTA"
);

export default createSchema({
	schemaType: "component",
	displayName: "Grouping Element",
	component: "GroupingElement",
	dataPacks: null,
	category: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Component",
					type: "ComponentArray",
					mandatory: true,
					whiteList: [
						"TestimonialsLanding",
						...filteredModuleList,
						...(instance.includes("UDDI") ? ["OverviewEcommerce"] : []),
					],
					key: "componentModules",
				},
			],
		},
	],
	default: {
		component: "GroupingElement",
		title: "Section Name",
		componentModules: [
			{
				component: "BasicContent",
				title: { content: "Basic content title text", tag: "h2" },
				subtitle: "Subtitle text",
				detail: "Detail text",
				image: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
				imageText: "Image detail",
				button: {
					component: "Button",
					buttonText: "Link",
					appearance: "link",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
				layout: "L001",
			},
		],
	},
	thumbnails: {},
});
