import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Address Collection",
	component: "AddressCollection",
	dataPacks: null,
	category: "content",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Address",
					type: "RichText",
					key: "address",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Email",
					type: "TextField",
					key: "email",
					hideable: true,
				},
				{
					title: "Phone",
					type: "TextField",
					key: "phone",
					hideable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["defaultAlt"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "AddressCollection",
		title: { content: "Title", tag: "h3" },
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		address: "Lorem ipsum dolor sit amet",
		email: "email@email.com",
		phone: "+34 (0) 00 00 00 00 00",
		button: {
			component: "Button",
			buttonText: "Cómo Llegar",
			appearance: "secondary",
			size: "big",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		theme: "defaultAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AddressCollection_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/AddressCollection_x2.png`,
	},
});
