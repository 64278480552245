import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Card Collection",
	component: "CardCollection",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Subtitle",
					type: "RichText",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Cards",
					type: "ComponentArray",
					elementUniqueSelection: true,
					mandatory: true,
					maxItems: null,
					key: "elements",
					whiteList: [
						"BasicCard",
						"IconCard",
						"ImageCard",
						"LinkCard",
						"SolidCard",
					],
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/layout001_x2.png`,
						},
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/layout002_x2.png`,
						},
						{
							value: "L004",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/layout004_x2.png`,
						},
						{
							value: "L005",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/layout005_x2.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Card Style",
					key: "kind",
					type: "VisualUniqueSelection",
					elementUniqueSelection: true,
					options: [
						{
							value: "BasicCard",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/BasicCard_x2.png`,
						},
						{
							value: "IconCard",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/IconCard_x2.png`,
						},
						{
							value: "ImageCard",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/ImageCard_x2.png`,
						},
						{
							value: "LinkCard",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/LinkCard_x2.png`,
						},
						{
							value: "SolidCard",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/CardCollection/SolidCard_x2.png`,
						},
					],
					columns: 3,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("iade-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "CardCollection",
		title: { content: "Title", tag: "h2" },
		subtitle: "Lorem Ipsum",
		kind: "BasicCard",
		elements: [
			{
				component: "BasicCard",
				title: "Lorem ipsum",
				description:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				file: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					url: "",
				},
				veil: 0,
				button: {
					component: "Button",
					buttonText: "Link",
					appearance: "link",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
		],
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "L001",
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/CardCollection_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/CardCollection_x2.png`,
	},
});
