import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Event Distributor",
	component: "EventDistributor",
	category: "articlesAndEvents",
	dataPacks: ["EVENTS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Events",
					type: "ReferenceField",
					sources: [{ structuredData: "EVENTS" }],
					key: "data",
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/EventDistributor/layout001_x2.png`,
						},
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/EventDistributor/layout002_x2.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{
							...createThemeStyles("eeg-theme", ["default"]),
						},
						{
							...createThemeStyles("ipam-theme", ["default"]),
						},
						{
							...createThemeStyles("iade-theme", ["default"]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "EventDistributor",
		title: { content: "Title", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "EVENTS" }],
			order: "alpha",
			quantity: 15,
		},
		button: {
			component: "Button",
			buttonText: "Ver todos los eventos",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "L001",
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventDistributor_x2.png`,
	},
});
