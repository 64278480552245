import { instance } from "@instance-config";
import { createSchema } from "@utils/";

const isIADEES = instance.includes("iadeES");
const isCEG = instance.includes("CEG");
const isIADE = instance.includes("IADE");
const isIPAM = instance.includes("IPAM");

export default createSchema({
	schemaType: "module",
	displayName: "Hero Study",
	component: "HeroStudy",
	category: "featured",
	dataPacks: ["STUDY"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					mandatory: true,
					indexable: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					humanReadable: true,
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Hightlight Text",
					type: "TextField",
					humanReadable: true,
					key: "hightlightText",
					hideable: true,
				},
				...(isIADEES
					? [
							{
								title: "Logo Text",
								type: "TextField",
								humanReadable: true,
								key: "logoText",
								hideable: true,
							},
							{
								title: "Logo",
								type: "ImageField",
								key: "logoImage",
								hideable: true,
							},
					  ]
					: []),
				...(isIADE || isIPAM || isIADEES
					? [
							{
								title: "Study",
								type: "ReferenceField",
								sources: [{ structuredData: "STUDY" }],
								key: "data",
							},
					  ]
					: []),
			],
		},
	],

	default: {
		component: "HeroStudy",
		title: "Lorem ipsum dolor sit amet",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		hightlightText: "Lorem ipsum dolor sit amet",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: isCEG ? 0 : 70,
		},
		...((isIPAM || isIADE || isIADEES) && {
			data: {
				mode: "manual",
				sources: [{ structuredData: "STUDY" }],
				order: "alpha",
				quantity: 50,
				allLanguages: true,
			},
			hasDistributorData: true,
		}),
	},
	thumbnails: {
		"1x": `/thumbnails/modules/HeroStudy/thumbnail.png`,
		"2x": `/thumbnails/modules/HeroStudy/thumbnail-2x.png`,
	},
});
