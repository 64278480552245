import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Event Agenda",
	component: "EventAgenda",
	category: "collection",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Default placeholder" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Agenda elements",
					type: "ComponentArray",
					maxItems: null,
					whiteList: ["AgendaElement"],
					key: "componentModules",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default"]) },
						{ ...createThemeStyles("ipam-theme", ["default"]) },
						{ ...createThemeStyles("iade-theme", ["default"]) },
						{ ...createThemeStyles("iade-es-theme", ["default"]) },
						{ ...createThemeStyles("uddi-theme", ["default"]) },
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "EventAgenda",
		title: { content: "Agenda", tag: "h4" },
		componentModules: [
			{
				component: "AgendaElement",
				startTime: "00:00",
				description: "Lorem ipsum dolor sit amet",
			},
			{
				component: "AgendaElement",
				startTime: "00:00",
				description: "Lorem ipsum dolor sit amet",
			},
			{
				component: "AgendaElement",
				startTime: "00:00",
				description: "Lorem ipsum dolor sit amet",
			},
		],
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventAgenda_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventAgenda_x2.png`,
	},
});
