import thumbnail from "@axthumbs/modules/OverviewEcommerce.png";
import BirthdayField from "@schemas/commons/BirthdayField";
import FormFields from "@schemas/commons/FormFields";
import InternationalExtendedInformation from "@schemas/commons/InternationalExtendedInformation";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import ShowDefaultMethodOfAdmission from "@schemas/commons/ShowDefaultMethodOfAdmission";
import ShowSelectorBachelorDegree from "@schemas/commons/ShowSelectorBachelorDegree";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { deepClone } from "@translations/utils";
import { createSchema } from "@utils/";

const formFields = deepClone(FormFields);
formFields.content = formFields.content.filter(
	(field) => field.key !== "stickyCTA"
);
delete formFields.defaults.stickyCTA;

export default createSchema({
	schemaType: "module",
	displayName: "Overview E-commerce",
	component: "OverviewEcommerce",
	category: "FormType",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				{
					...BirthdayField.content,
				},
				{
					...InternationalExtendedInformation.content,
				},
				{
					...ShowSelectorBachelorDegree.content,
				},
				{
					...ShowDefaultMethodOfAdmission.content,
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					maxItems: 1,
					mandatory: true,
				},
				...PrivacyPoliceFormType.content,
				...formFields.content,
			],
		},
	],
	default: {
		title: "Overview E-commerce",
		component: "OverviewEcommerce",
		...TitleAndSubtitle.defaults,
		formType: "ProductLanding",
		...BirthdayField.default,
		...InternationalExtendedInformation.default,
		...ShowSelectorBachelorDegree.default,
		...ShowDefaultMethodOfAdmission.default,
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		...PrivacyPoliceFormType.default,
		...formFields.defaults,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
