import { instance } from "@instance-config";
import { basicModuleList, additionalModuleList } from "@schemas/modules";
import StickyFooterCTA from "@schemas/modules/StickyFooterCTA";

const BlogListv2 = {
	schemaType: "template",
	displayName: "Blog List",
	component: "BlogList",
	dataPacks: ["BLOG"],
	type: { label: "Blog", value: "blog", mode: "list" },
	content: [
		{
			title: "Hero section",
			type: "ComponentContainer",
			whiteList: ["InnerTitle"],
			key: "innerTitleComp",
		},
		{
			title: "Page Title",
			type: "HeadingField",
			humanReadable: true,
			key: "mainTitle",
			default: { tag: "h1", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
			advanced: true,
			hideable: true,
		},
		{
			title: "Subtitle",
			type: "RichText",
			humanReadable: true,
			key: "subtitle",
			hideable: true,
		},
		{
			title: "Categorías",
			type: "ComponentContainer",
			whiteList: ["BlogCategoriesMenu"],
			key: "categoriesMenu",
		},
		{
			title: "Nota",
			key: "noteReferenceBlog",
			type: "NoteField",
		},
		{
			title: "Blog",
			type: "ReferenceField",
			sources: [{ structuredData: "BLOG" }],
			key: "data",
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			type: "UniqueCheck",
			key: "areFiltersVisible",
			options: [
				{
					title: "Show filters",
				},
			],
		},
		//Desactivo globalOperator y filterOperator porque aun no se pueden editar en la API privada en AX
		// {
		//   title: 'Filter between categories',
		//   type: 'Select',
		//   key: 'globalOperator',
		//   hideable: true,
		//   options: [
		//     { value: 'AND', label: 'Coincidir todos (AND)' },
		//     { value: 'OR', label: 'Coincidir alguno (OR)' },
		//   ],
		// },
		// {
		//   title: 'Filter between options of each category',
		//   type: 'Select',
		//   key: 'filterOperator',
		//   hideable: true,
		//   options: [
		//     { value: 'AND', label: 'Coincidir todos (AND)' },
		//     { value: 'OR', label: 'Coincidir alguno (OR)' },
		//   ],
		// },
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		component: "BlogList",
		type: "template",
		templateType: "BlogList",
		mainTitle: { content: "Title", tag: "h1" },
		areFiltersVisible: false,
		innerTitleComp: {
			component: "InnerTitle",
			title: {
				content: "Title",
				tag: "h1",
			},
			subtitle: "",
			backgroundImage: {
				component: "Image",
				alt: "Alt",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 0,
			},
			icon: false,
			breadcrumbsOnly: true,
			theme: "default",
			size: "small",
		},
		categoriesMenu: {
			component: "BlogCategoriesMenu",
			data: {
				mode: "auto",
				sources: [{ structuredData: "BLOG_CATEGORY" }],
				order: "alpha",
				quantity: 0,
				fullRelations: true,
			},
			allCategoriesTitle: "Todas las categorías",
			allCategoriesUrl: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
			hasDistributorData: true,
		},
		noteReferenceBlog: {
			title: "Filtros de categorías y tags",
			text: "Para que aparezcan filtradas las entradas por categorías y etiquetas, hay que modificarlo en el siguiente distribuidor. Si no aparece alguna categoría en el menú, es porque hace falta añadir la URL en los datos estructurados. Se recomienda guardar y refrescar la página cuando se hagan cambios en los filtros.",
		},
		hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "BLOG" }],
			order: "updateDate-DESC",
			quantity: 0,
			fullRelations: true,
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
		itemsPerPage:
			instance.includes("IPAM") || instance.includes("IADE") ? 18 : 6,
		// globalOperator: instance.includes('CEG') && 'AND',
		// filterOperator: instance.includes('CEG') && 'OR',
	},

	thumbnails: {},
};

const BlogListv1 = {
	schemaType: "template",
	displayName: "Blog List",
	component: "BlogList",
	dataPacks: ["BLOG"],
	type: { label: "Blog", value: "blog", mode: "list" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Blog Title",
			type: "HeadingField",
			humanReadable: true,
			key: "newsTitle",
			advanced: true,
			hideable: true,
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			title: "Blog",
			type: "ReferenceField",
			sources: [{ structuredData: "BLOG" }],
			key: "data",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		component: "BlogList",
		type: "template",
		templateType: "BlogList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		newsTitle: { content: "Title", tag: "h2" },
		hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "BLOG" }],
			order: "publicationDate-DESC",
			quantity: 0,
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
					],
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L004",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "ImageCard",
					elements: [
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
					],
					layout: "L004",
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
				StickyFooterCTA.default,
			],
		},
		itemsPerPage:
			instance.includes("IPAM") || instance.includes("IADE") ? 18 : 6,
	},

	thumbnails: {},
};

const BlogList = instance.includes("CEG") ? BlogListv2 : BlogListv1;

export default BlogList;
