import { basicHeroList, basicModuleList } from "@schemas/modules";
import StickyFooterCTA from "@schemas/modules/StickyFooterCTA";

const filteredModuleList = basicModuleList.filter(
	(element) => element !== "StaffBio" && element !== "StaffDistributor"
);

export default {
	schemaType: "template",
	displayName: "Basic Template",
	component: "BasicTemplate",
	dataPacks: null,
	type: { label: "Static", value: "static" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: [...basicHeroList],
			key: "heroSection",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...filteredModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
			sectionPosition: 1,
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [StickyFooterCTA.default],
			sectionPosition: 2,
		},
	},

	thumbnails: {},
};
