import { instance } from "@instance-config";
import { basicModuleList, additionalModuleList } from "@schemas/modules";
import StickyFooterCTA from "@schemas/modules/StickyFooterCTA";

export default {
	schemaType: "template",
	displayName: "Event Detail",
	component: "EventDetail",
	dataPacks: ["EVENTS"],
	type: { label: "Events", value: "events", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Image",
			type: "ImageField",
			key: "image",
			mandatory: true,
		},
		{
			title: "Event Date",
			type: "DateField",
			key: "eventDate",
			mandatory: true,
			selectsRange: true,
		},
		{
			title: "Event Address",
			type: "TextField",
			humanReadable: true,
			key: "eventAddress",
			mandatory: true,
		},
		{
			title: "Event Hour",
			placeholder: "hh:mm",
			helptext: "hh:mm",
			type: "TextField",
			humanReadable: true,
			key: "eventHour",
			mandatory: true,
		},
		...(instance.includes("IPAM") || instance.includes("IADE")
			? [
					{
						title: "Event Type",
						type: "RadioGroup",
						key: "eventType",
						options: [
							{
								title: "Open Day",
								name: "openDay",
								value: "Open Day",
							},
							{
								title: "Master Open Week",
								name: "masterOpenWeek",
								value: "Master Open Week",
							},
							{
								title: "Generic",
								name: "generic",
								value: "Genérico",
							},
						],
					},
					{
						title: "Programs List",
						key: "programsList",
						type: "AsyncCheckGroup",
						source: "STUDY",
						mandatory: false,
						condition: false,
						allLanguages: true,
					},
			  ]
			: [
					{
						title: "Event Type",
						type: "RadioGroup",
						key: "eventType",
						options: [
							{
								title: "HyFliex",
								name: "hyFliex",
								value: "Presencial, Online",
							},
							{
								title: "Online",
								name: "online",
								value: "Online",
							},
							{
								title: "In person",
								name: "presencial",
								value: "Presencial",
							},
						],
					},
					{
						title: "Form Type",
						type: "ConditionalField",
						key: "conditionalForm",
						mandatory: true,
						options: [
							{
								value: true,
								title: "Generic Event",
								name: "genericEvent",
							},
							{
								value: false,
								title: "JPA",
								name: "JPA",
							},
						],
						fields: [
							{
								title: "Campaign ID",
								type: "TextField",
								key: "uECRM",
								condition: true,
							},
							{
								title: "Programs List",
								type: "AsyncCheckGroup",
								source: "STUDY",
								key: "programsList",
								condition: false,
								allLanguages: true,
							},
							...(instance.includes("iadeES")
								? [
										{
											title: "Campaign ID",
											type: "TextField",
											key: "uECRM",
											condition: false,
										},
								  ]
								: []),
						],
					},
			  ]),
		{
			title: "Event Title",
			type: "HeadingField",
			humanReadable: true,
			key: "eventTitle",
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
			advanced: true,
			mandatory: true,
		},
		{
			title: "Description",
			type: "RichText",
			humanReadable: true,
			key: "description",
			mandatory: true,
		},
		{
			title: "Sponsors Logos",
			type: "ComponentContainer",
			maxItems: 1,
			whiteList: ["SponsorsLogos"],
			key: "sponsorsLogos",
			hideable: true,
		},
		{
			title: "Event Agenda",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["EventAgenda"],
			key: "eventAgenda",
			hideable: true,
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "EventDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		conditionalForm: true,
		uECRM: "",
		programsList: [],
		eventTitle: { content: "New Title", tag: "h3" },
		description: "Lorem Ipsum",
		eventDate: "2021/01/01",
		eventAddress: "Lorem Ipsum",
		eventHour: "00:00",
		...(instance.includes("IPAM") || instance.includes("IADE")
			? {
					eventType: "Open Day",
			  }
			: {
					eventType: "Presencial, Online",
			  }),
		sponsorsLogos: {
			component: "SponsorsLogos",
			title: null,
			subtitle: null,
			button: {
				component: "Button",
			},
			data: {
				mode: "auto",
				sources: [{ structuredData: "COMPANIES" }],
				order: "alpha",
				quantity: 15,
			},
			hasDistributorData: true,
		},
		eventAgenda: {
			component: "Section",
			name: "EventAgenda",
			modules: [
				{
					component: "EventAgenda",
					title: { content: "Agenda", tag: "h4" },
					componentModules: [
						{
							component: "AgendaElement",
							startTime: "00:00",
							description: "Lorem ipsum dolor sit amet",
						},
						{
							component: "AgendaElement",
							startTime: "00:00",
							description: "Lorem ipsum dolor sit amet",
						},
						{
							component: "AgendaElement",
							startTime: "00:00",
							description: "Lorem ipsum dolor sit amet",
						},
					],
				},
			],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "SocialShare",
					title: "Share",
				},
				{
					component: "EventDistributor",
					title: { content: "Title", tag: "h2" },
					data: {
						mode: "auto",
						sources: [{ structuredData: "EVENTS" }],
						order: "alpha",
						quantity: 15,
					},
					theme: instance.includes("IPAM") ? "defaultAlt" : "default",
					button: {
						component: "Button",
						buttonText: "Ver todos los eventos",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L001",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
					],
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L004",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "ImageCard",
					elements: [
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
					],
					layout: "L004",
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
				StickyFooterCTA.default,
			],
		},
	},

	thumbnails: {},
};
