import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Hero E-Commerce",
	component: "HeroEcommerce",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
					mandatory: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					humanReadable: true,
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					hideable: true,
				},
				{
					title: "Price",
					type: "TextField",
					humanReadable: true,
					key: "price",
					hideable: true,
				},
				{
					title: "Discounted Price",
					type: "TextField",
					humanReadable: true,
					key: "discountedPrice",
					hideable: true,
				},
				{
					title: "Discount Tag",
					type: "TextField",
					humanReadable: true,
					key: "discountTag",
					hideable: true,
				},
				{
					title: "Discount Text",
					type: "TextField",
					humanReadable: true,
					key: "discountText",
					hideable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "HeroEcommerce",
		title: "Lorem ipsum",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		description: "Lorem ipsum dolor sit amet",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		price: "2.000 €",
		discountedPrice: "1.400 €",
		discountTag: "30% dto.",
		discountText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		button: {
			component: "Button",
			buttonText: "Solicita información",
			appearance: "transparent",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
	thumbnails: {
		"1x": `/thumbnails/modules/HeroEcommerce/thumbnail_x1.png`,
		"2x": `/thumbnails/modules/HeroEcommerce/thumbnail_x2.png`,
	},
});
