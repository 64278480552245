import thumbnail1x from "@axthumbs/thumbnails/HighlightedDegrees/thumbnail@1x.png";
import thumbnail2x from "@axthumbs/thumbnails/HighlightedDegrees/thumbnail@2x.png";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Highlighted Degrees",
	component: "HighlightedDegrees",
	category: "collection",
	dataPacks: ["STUDY"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: false,
				},
				{
					title: "Study",
					type: "ReferenceField",
					sources: [{ structuredData: "STUDY" }],
					key: "data",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("ipam-theme", ['default", "accentAlt']) },
						{ ...createThemeStyles("iade-theme", ["default", "accentAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HighlightedDegrees",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STUDY" }],
			order: "alpha",
			quantity: 15,
			allLanguages: true,
		},
		hasDistributorData: true,
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
	},
	thumbnails: {
		"1x": thumbnail1x,
		"2x": thumbnail2x,
	},
});
