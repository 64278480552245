import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import { createSchema } from "@utils/";

const isUDDI = instance.includes("UDDI");
const isEEG = instance.includes("EEG");
const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");

export default createSchema({
	schemaType: "module",
	displayName: "Sticky FooterCTA",
	component: "StickyFooterCTA",
	dataPacks: null,
	category: "interactive",
	configTabs: [
		{
			title: "content",
			fields: isEEG
				? [
						{
							title: "Button",
							type: "ComponentContainer",
							whiteList: ["Button"],
							key: "button",
							mandatory: true,
						},
				  ]
				: isIPAM || isIADE
				? []
				: [
						{
							title: "Phone",
							type: "TextField",
							key: "phoneNumber",
							mandatory: true,
						},
						{
							title: "Button",
							type: "ComponentContainer",
							whiteList: ["Button"],
							key: "button",
							mandatory: false,
						},
				  ],
		},
	],

	default: isEEG
		? {
				component: "StickyFooterCTA",
				button: {
					component: "Button",
					buttonText: "Solicita información",
					appearance: "primary",
					size: "medium",
					url: {
						href: "comunicacion@europaeducationgroup.com",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
		  }
		: isIPAM || isIADE
		? {
				component: "StickyFooterCTA",
		  }
		: {
				component: "StickyFooterCTA",
				phoneNumber: "+34 91 514 53 30",
				button: {
					component: "Button",
					buttonText: isUDDI ? "Infórmate" : "Solicita información",
					appearance: isUDDI ? "secondary" : "primary",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
		  },
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StickyFooterCTA_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StickyFooterCTA_x2.png`,
	},
});
