import { instance } from "@instance-config";
import { basicModuleList, additionalModuleList } from "@schemas/modules";
import StickyFooterCTA from "@schemas/modules/StickyFooterCTA";

export default {
	schemaType: "template",
	displayName: "Blog Detail",
	component: "BlogDetail",
	dataPacks: ["BLOG"],
	type: { label: "BLOG", value: "BLOG", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Categories",
			type: "AsyncCheckGroup",
			source: "NEWS_AREAS",
			key: "categories",
			mandatory: false,
			hideable: true,
		},
		{
			title: "Blogs",
			type: "ReferenceField",
			sources: [{ structuredData: "BLOG" }],
			key: "data",
		},
		{
			title: "Button",
			type: "ComponentContainer",
			whiteList: ["Button"],
			key: "button",
			hideable: true,
		},
		{
			title: "Image",
			type: "ImageField",
			key: "image",
			mandatory: true,
		},
		{
			title: "Main Title",
			type: "TextField",
			humanReadable: true,
			key: "mainTitle",
			mandatory: true,
		},
		{
			title: "Publication Date",
			type: "DateField",
			key: "publicationDate",
			selectsRange: true,
			mandatory: true,
			indexable: true,
		},
		{
			title: "Subtitle",
			type: "TextField",
			humanReadable: true,
			key: "subtitle",
			mandatory: false,
			hideable: true,
		},
		{
			type: "Wysiwyg",
			placeholder: "Type a news detail",
			humanReadable: true,
			title: "Content",
			key: "content",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BlogDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		categories: [],
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		data: {
			mode: "auto",
			sources: [{ structuredData: "BLOG" }],
			order: "recent-DESC",
			quantity: 3,
		},
		hasDistributorData: true,
		mainTitle: "Lorem Ipsum",
		publicationDate: "2021/01/01",
		subtitle:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacus risus, fermentum vitae quam vel, semper malesuada magna. Fusce cursus tempus nunc, vitae suscipit turpis dictum id. Nulla orci neque, elementum nec magna a, placerat bibendum lectus.",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacus risus, fermentum vitae quam vel, semper malesuada magna. Fusce cursus tempus nunc, vitae suscipit turpis dictum id. Nulla orci neque, elementum nec magna a, placerat bibendum lectus. Proin ac gravida nibh. Sed lectus nibh, eleifend ut iaculis non, venenatis at enim. Nulla dui magna, feugiat ut eleifend vitae, porttitor vel tellus. Mauris magna dolor, tincidunt vel leo quis, elementum bibendum est. Duis tincidunt ornare tincidunt. Donec pulvinar, ipsum et viverra varius, purus nunc facilisis arcu, a vulputate est eros ut purus. Proin vel arcu semper, semper elit eu, euismod quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras pretium, neque quis varius cursus, nisl elit feugiat nunc, sit amet efficitur metus libero vel nibh. Phasellus euismod massa at nulla aliquam, fermentum feugiat dolor elementum. ",
		button: {
			component: "Button",
			buttonText:
				instance.includes("IADE") || instance.includes("IPAM")
					? "Ver mais posts"
					: "Volver al listado de blog",
			appearance: "secondary",
			size: "big",
			url: {
				href: "",
				linkToURL: null,
				newTab: false,
				noFollow: false,
			},
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "SocialShare",
					title: "Share",
					theme: "defaultAlt",
				},
				StickyFooterCTA.default,
			],
		},
	},

	thumbnails: {},
};
