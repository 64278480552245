import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Featured Audiovisual",
	component: "FeaturedAudiovisual",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Video Big",
					type: "ComponentContainer",
					whiteList: ["Video"],
					key: "videoBig",
					mandatory: true,
				},
				{
					title: "Video Small",
					type: "ComponentContainer",
					whiteList: ["Video"],
					key: "videoSmall",
					mandatory: true,
				},
				{
					title: "Description Card",
					type: "ComponentContainer",
					whiteList: ["DescriptionCard"],
					key: "descriptionCard",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["defaultAlt"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		videoBig: {
			component: "Video",
			thumbnail: {
				component: "Image",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 40,
			},
			url: "",
		},
		videoSmall: {
			component: "Video",
			thumbnail: {
				component: "Image",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 40,
			},
			url: "",
		},
		descriptionCard: {
			component: "DescriptionCard",
			title: {
				content: "Description Card Title",
				tag: "h4",
			},
			description: "Lorem ipsum dolor sit amet",
			button: {
				component: "Button",
				buttonText: "Ver detalle",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
		},
		theme: "defaultAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedAudiovisual_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedAudiovisual_x2.png`,
	},
});
