import thumbnail1x from "@axthumbs/thumbnails/JobOfferDistributor/thumbnail@1x.png";
import thumbnail2x from "@axthumbs/thumbnails/JobOfferDistributor/thumbnail@2x.png";
import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "JobOffer Distributor",
	component: "JobOfferDistributor",
	dataPacks: ["JOB_OFFERS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "JobOffers",
					type: "ReferenceField",
					sources: [{ structuredData: "JOB_OFFERS" }],
					key: "data",
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/JobOfferDistributor/layout001_x2.png`,
						},
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/JobOfferDistributor/layout002_x2.png`,
						},
					],
					columns: 2,
				},
			],
		},
	],

	default: {
		component: "JobOfferDistributor",
		title: { content: "Otras ofertas", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "JOB_OFFERS" }],
			order: "recent-ASC",
			quantity: 3,
		},
		button: {
			component: "Button",
			buttonText: "Ver todas los ofertas",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "L001",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": thumbnail1x,
		"2x": thumbnail2x,
	},
});
