import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Featured Block",
	component: "FeaturedBlock",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "FeaturedBlock",
		title: {
			content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
			tag: "h2",
		},
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedBlock_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedBlock_x2.png`,
	},
});
