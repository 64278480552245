import Awards from "@dataPacks/structuredData/Awards";
import Blog from "@dataPacks/structuredData/Blog";
import BlogAuthor from "@dataPacks/structuredData/BlogAuthor";
import BlogCategory from "@dataPacks/structuredData/BlogCategory";
import BlogTag from "@dataPacks/structuredData/BlogTag";
import BlogV2 from "@dataPacks/structuredData/BlogV2";
import Companies from "@dataPacks/structuredData/Companies";
import CourseMode from "@dataPacks/structuredData/CourseMode";
import Discounts from "@dataPacks/structuredData/Discounts";
import Events from "@dataPacks/structuredData/Events";
import ExternalStudy from "@dataPacks/structuredData/ExternalStudy";
import InfoLanding from "@dataPacks/structuredData/InfoLanding";
import JobOffers from "@dataPacks/structuredData/JobOffers";
import KnowledgeArea from "@dataPacks/structuredData/KnowledgeArea";
import Landings from "@dataPacks/structuredData/Landings";
import LandingType from "@dataPacks/structuredData/LandingType";
import Language from "@dataPacks/structuredData/Language";
import LanguageCEG from "@dataPacks/structuredData/LanguageCEG";
import Location from "@dataPacks/structuredData/Location";
import LocationCEG from "@dataPacks/structuredData/LocationCEG";
import Modals from "@dataPacks/structuredData/Modals";
import News from "@dataPacks/structuredData/News";
import PrivacyPolicy from "@dataPacks/structuredData/PrivacyPolicy";
import QuickFacts from "@dataPacks/structuredData/QuickFacts";
import Quizz from "@dataPacks/structuredData/Quizz";
import Staff from "@dataPacks/structuredData/Staff";
import Study from "@dataPacks/structuredData/Study";
import StudyLanding from "@dataPacks/structuredData/StudyLanding";
import StudyLandingType from "@dataPacks/structuredData/StudyLandingType";
import StudyType from "@dataPacks/structuredData/StudyType";
import Testimonials from "@dataPacks/structuredData/Testimonials";
import { instance } from "@instance-config";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isCEG = instance.includes("CEG");
const isIADEES = instance.includes("iadeES");
const isUDDI = instance.includes("UDDI");

export default {
	...Events,
	...Staff,
	...News,
	...Testimonials,
	...Companies,
	...Awards,
	...Study,
	...StudyType,
	...(!!isIPAM || !!isIADE || isIADEES ? { ...Location, ...Language } : {}),
	...(isCEG ? { ...LocationCEG } : {}),
	...(isCEG ? { ...LanguageCEG } : {}),
	...(isIADE || isIADEES || isIPAM ? { ...KnowledgeArea } : {}),
	...StudyLanding,
	...StudyLandingType,
	...Landings,
	...(isCEG
		? { ...JobOffers, ...BlogV2, ...BlogCategory, ...BlogTag, ...BlogAuthor }
		: { ...Blog }),
	...PrivacyPolicy,
	...QuickFacts,
	...ExternalStudy,
	...LandingType,
	...InfoLanding,
	...CourseMode,
	...((!!isIPAM || !!isIADE || !!isIADEES) && { ...Modals }),
	...((!!isIPAM || !!isIADE || !!isIADEES || isUDDI) && { ...Discounts }),
	...((isIPAM || isIADE) && { ...Quizz }),
};
