import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Inner Title",
	component: "InnerTitle",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "HeadingField",
					key: "title",
					mandatory: true,
					advanced: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Show Icon",
					type: "RadioGroup",
					key: "icon",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "Show breadcrumbs only",
					type: "RadioGroup",
					key: "breadcrumbsOnly",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "InnerTitle",
		title: {
			content: "Rankings y Reconocimientos",
			tag: "h4",
		},
		subtitle:
			"El Centro de Estudios Garrigues es, un año más, nº1  en los principales rankings de postgrado nacionales.",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		icon: false,
		breadcrumbsOnly: false,
		theme:
			instance.includes("IPAM") || instance.includes("IADE")
				? "accent"
				: "default",
	},
	thumbnails: {
		"1x": `/thumbnails/modules/InnerTitle/thumbnail.png`,
		"2x": `/thumbnails/modules/InnerTitle/thumbnail-2x.png`,
	},
});
