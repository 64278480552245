import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

const isIADEES = instance.includes("iadeEs");
const isUDDI = instance.includes("UDDI");

export default createSchema({
	schemaType: "module",
	displayName: "Program Box",
	component: "ProgramBox",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: false,
					default: { tag: "h4", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Ranking",
					type: "RichText",
					key: "ranking",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Date",
					type: "RichText",
					key: "date",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Site",
					type: "RichText",
					key: "site",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Time",
					type: "RichText",
					key: "time",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Credits",
					type: "RichText",
					key: "credits",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "ButtonToTop",
					type: "ComponentContainer",
					whiteList: ["ButtonToTop"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", ["defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "ProgramBox",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		title: {
			content: "Title ",
			tag: "h4",
		},
		theme: isIADEES || isUDDI ? "defaultAlt" : "default",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		ranking: "Lorem ipsum dolor sit amet",
		date: "Lorem ipsum dolor",
		site: "Lorem ipsum dolor",
		time: "Lorem ipsum dolor",
		credits: "Lorem ipsum dolor",
		button: {
			component: "ButtonToTop",
			buttonText: "Solicita tu admisión",
			appearance: "primary",
			size: "medium",
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/ProgramBox_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/ProgramBox_x2.png`,
	},
});
