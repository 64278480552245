import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Testimonials",
	component: "Testimonials",
	category: "collections",
	dataPacks: ["TESTIMONIALS"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Card Title (L003)",
					type: "HeadingField",
					humanReadable: true,
					key: "manualTitle",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Testimonials",
					type: "ReferenceField",
					sources: [{ structuredData: "TESTIMONIALS" }],
					key: "data",
				},
				{
					title: "Grouping Link",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: "/thumbnails/modules/Testimonials/layouts/layout1.png",
						},
						{
							value: "L002",
							img: "/thumbnails/modules/Testimonials/layouts/layout2.png",
						},
						{
							value: "L003",
							img: "/thumbnails/modules/Testimonials/layouts/layout3.png",
						},
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "accentAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "accentAlt"]) },
						{ ...createThemeStyles("iade-es-theme", ["default", "accentAlt"]) },
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "Testimonials",
		title: { content: "Title", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "TESTIMONIALS" }],
			order: "alpha",
			quantity: 15,
		},
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "L001",
		theme: "default",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `/thumbnails/modules/Testimonials/thumbnail.png`,
		"2x": `/thumbnails/modules/Testimonials/thumbnail.png`,
	},
});
