import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Staff Carousel Landing",
	component: "StaffCarouselLanding",
	category: "collections",
	dataPacks: ["STAFF"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},

				{
					title: "Staff Carousel Landing",
					type: "ReferenceField",
					sources: [{ structuredData: "STAFF" }],
					key: "data",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["defaultAlt"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("ipam-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("iade-theme", [
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StaffCarouselLanding",
		title: { content: "Title", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "STAFF" }],
			order: "alpha",
			quantity: 15,
		},
		layout: "L001",
		theme: "defaultAlt",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffCarouselLanding_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffCarouselLanding_x2.png`,
	},
});
